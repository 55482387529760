import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section, Icon, Image, Link } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
import { MdDoneAll } from "react-icons/md"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Strona główna | Wildy ATV Rentals</title>
        <meta
          name={"description"}
          content={"Gdzie zaczyna się przygoda na czterech kołach"}
        />
        <meta
          property={"og:title"}
          content={"Strona główna | Wildy ATV Rentals"}
        />
        <meta
          property={"og:description"}
          content={"Gdzie zaczyna się przygoda na czterech kołach"}
        />
        <meta
          property={"og:image"}
          content={"https://zixelux.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://zixelux.com/img/Z-Logo-app-icon.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://zixelux.com/img/Z-Logo-app-icon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://zixelux.com/img/Z-Logo-app-icon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://zixelux.com/img/Z-Logo-app-icon.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://zixelux.com/img/Z-Logo-app-icon.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://zixelux.com/img/Z-Logo-app-icon.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="140px 0 210px"
        sm-padding="40px 0 40px 0"
        background="linear-gradient(0deg,rgba(0, 0, 0, 0.6) 0%,rgba(0, 0, 0, 0.6) 100%),#000000 url(https://zixelux.com/img/1.jpg) center/cover"
      >
        <Override slot="SectionContent" sm-align-items="center" />
        <Box
          max-width="560px"
          padding="50px 80px 80px 50px"
          background="--color-light"
          color="--dark"
        >
          <Text
            as="h4"
            font="--base"
            color="--grey"
            letter-spacing="1px"
            text-transform="uppercase"
            margin="6px 0"
          >
            ATV Rentals
          </Text>
          <Text as="h1" font="--headline2" margin="0 0 12px 0">
            Wildy
          </Text>
          <Text font="--base">
            Cieszymy się, że zdecydowałeś się odkrywać z nami wspaniałą
            przyrodę. W Wildy zapewniamy najwyższej jakości wypożyczalnie ATV
            dla wszystkich poziomów umiejętności, zapewniając, że Twoja przygoda
            będzie nie tylko ekscytująca, ale także bezpieczna i wygodna.
            Niezależnie od tego, czy planujesz dzień eksploracji, czy szukasz
            nowego sposobu na poznanie dzikiej przyrody, jesteśmy tutaj, aby
            uczynić go niezapomnianym.
          </Text>
        </Box>
      </Section>
      <Section color="--dark">
        <Text
          as="h2"
          font="--headline2"
          md-font="--headline2"
          margin="20px 0 0 0"
        >
          Dlaczego warto wybrać Wildy?
        </Text>
        <Text
          margin="0px 0px 0px 0px"
          color="--darkL1"
          font="--lead"
          lg-text-align="center"
          display="flex"
          align-items="flex-start"
        >
          Wildy wyróżnia się zaangażowaniem w jakość, wygodę i przygodę. Oto
          dlaczego powinieneś wybrać właśnie nas:
        </Text>
        <Box
          margin="36px 0 0 0"
          padding="0 0 0 54px"
          sm-padding="54px 0 0 0"
          position="relative"
          sm-margin="24px 0 0 0"
        >
          <Icon
            position="absolute"
            top="8px"
            left="0"
            size="36px"
            color="--primary"
            category="md"
            icon={MdDoneAll}
          />
          <Text as="h3" font="--headline3" margin="10px 0">
            Najwyższej klasy pojazdy ATV
          </Text>
          <Text as="p" font="--lead" margin="10px 0" color="--greyD2">
            Nasza flota jest regularnie serwisowana i aktualizowana, aby
            zapewnić najlepszą jazdę za każdym razem.
          </Text>
        </Box>
        <Box
          margin="36px 0 0 0"
          padding="0 0 0 54px"
          sm-padding="54px 0 0 0"
          position="relative"
          sm-margin="24px 0 0 0"
        >
          <Icon
            position="absolute"
            top="8px"
            left="0"
            size="36px"
            color="--primary"
            category="md"
            icon={MdDoneAll}
          />
          <Text as="h3" font="--headline3" margin="10px 0">
            Spersonalizowane trasy
          </Text>
          <Text as="p" font="--lead" margin="10px 0" color="--greyD2">
            Dostosuj swoją przygodę do własnych potrzeb dzięki naszej ofercie
            szlaków. Od łatwych przejażdżek dla początkujących po wymagające
            ścieżki dla bardziej doświadczonych, mamy wszystko.
          </Text>
        </Box>
        <Box
          margin="36px 0 0 0"
          padding="0 0 0 54px"
          sm-padding="54px 0 0 0"
          position="relative"
          sm-margin="24px 0 0 0"
        >
          <Icon
            position="absolute"
            top="8px"
            left="0"
            size="36px"
            color="--primary"
            category="md"
            icon={MdDoneAll}
          />
          <Text as="h3" font="--headline3" margin="10px 0">
            Bezpieczeństwo przede wszystkim
          </Text>
          <Text as="p" font="--lead" margin="10px 0" color="--greyD2">
            Zapewniamy kompleksowe instrukcje bezpieczeństwa i sprzęt, aby
            zapewnić ochronę podczas podróży.
          </Text>
        </Box>
        <Box
          margin="36px 0 0 0"
          padding="0 0 0 54px"
          sm-padding="54px 0 0 0"
          position="relative"
          sm-margin="24px 0 0 0"
        >
          <Icon
            position="absolute"
            top="8px"
            left="0"
            size="36px"
            color="--primary"
            category="md"
            icon={MdDoneAll}
          />
          <Text as="h3" font="--headline3" margin="10px 0">
            Przyjazny zespół wsparcia
          </Text>
          <Text as="p" font="--lead" margin="10px 0" color="--greyD2">
            Nasz kompetentny personel jest tutaj, aby pomóc Ci od początku do
            końca, zapewniając płynne i przyjemne doświadczenie.
          </Text>
        </Box>
      </Section>
      <Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          justify-content="flex-start"
          lg-width="100%"
          align-items="flex-start"
          lg-margin="0px 0px 32px 0px"
          margin="0px 0px 0px 0px"
          padding="0px 32px 0px 0px"
          lg-padding="0px 0px 0px 0px"
          lg-justify-content="center"
        >
          <Image
            src="https://zixelux.com/img/2.jpg"
            object-fit="cover"
            width="100%"
            height="100%"
            border-radius="24px"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            sm-min-height="100vw"
            max-height="380px"
          />
        </Box>
        <Box
          display="flex"
          width="50%"
          flex-direction="column"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="16px 0px 16px 16px"
          justify-content="center"
        >
          <Text
            margin="0px 0px 40px 0px"
            color="--greyD3"
            font="--lead"
            lg-text-align="center"
          >
            Gotowy na rozpoczęcie przygody? Oto jak możesz się z nami
            skontaktować
          </Text>
          <Link
            href="/contacts"
            padding="12px 24px 12px 24px"
            color="--light"
            text-decoration-line="initial"
            font="--lead"
            border-radius="8px"
            margin="0px 16px 0px 0px"
            transition="background-color 0.2s ease-in-out 0s"
            sm-margin="0px 0px 16px 0px"
            sm-text-align="center"
            background="--color-primary"
            hover-transition="background-color 0.2s ease-in-out 0s"
            hover-background="--color-primary"
          >
            Skontaktuj się z nami
          </Link>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
